window.d3 = require("d3")
require("d3-scale-chromatic")
window.d3.tip = require("d3-tip").default

window.dc = require("dc")
// require("dc-addons/dist/tooltip/dc-tooltip-mixin")

import "dc/dist/style/dc.css"
// import "dc-addons/dist/tooltip/dc-tooltip-mixin.css"

window.crossfilter = require("crossfilter2").default
